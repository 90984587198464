@import "/home/runner/work/caritas-onlineBeratung-frontend/caritas-onlineBeratung-frontend/src/resources/styles/settings.scss";
.stageLayout {
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100%;

	&__header,
	&__footer {
		flex: 0;
		padding: 0 $grid-base-two $grid-base-four;
		@include breakpoint($fromLarge) {
			padding: 0 $grid-base-three $grid-base-four;
		}
	}

	&__content {
		flex: 1;
	}

	&__stage {
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		z-index: 20;
		width: 40vw;
	}

	&__header {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-basis: $header-height;
		height: $header-height;
		width: 100%;
		padding: 0 $grid-base-three;

		&.mobile {
			justify-content: space-between;
			padding: 0 $grid-base-two;
		}
	}

	&__content {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: auto;
		padding: 48px 16px;

		@include breakpoint($fromLarge) {
			& > *:not(.loadingIndicator) {
				max-width: 700px;
			}
		}

		@include breakpoint($fromXLarge) {
			width: 100%;
			padding-left: calc(40vw + 16px);

			& > *:not(.loadingIndicator) {
				max-width: 700px;
				width: calc(60vw - 300px);
			}
		}
	}

	&__toLogin {
		&__text {
			display: none;
			@media screen and (min-width: 768px) and (max-width: 900px) {
				display: inline-block;
			}
			@media screen and (min-width: 1200px) {
				display: inline-block;
			}
		}

		&__button {
			display: inline-block;
			margin-top: 0;
			.button__item {
				color: $text-high-emphasis;
				border: 2px solid $secondary;
				&:hover {
					background-color: var(
						--skin-color-primary-hover,
						$hover-primary
					);
					color: var(--text-color-contrast-switch, $white);
					border-color: var(
						--skin-color-primary-hover,
						$hover-primary
					);
				}
			}
		}
	}

	&__legalLinks {
		display: flex;
		align-items: center;
		margin: 0;

		@include breakpoint($fromXLarge) {
			justify-content: flex-end;
		}

		.button-as-link {
			background: none;
			border: none;
			cursor: pointer;
			text-decoration: underline;
			padding: 0;
			white-space: nowrap;
			color: $tertiary;

			&:focus {
				outline: $focus-outline;
				border-radius: 4px;
			}

			&:focus:not(:focus-visible) {
				outline: none;
			}

			&:hover {
				color: var(--skin-color-primary-hover, $hover-primary);
			}
		}

		.text {
			font-size: $font-size-tertiary;
		}
	}

	&__legalLinksItem {
		color: $tertiary;
		&:hover {
			color: var(--skin-color-primary-hover, $hover-primary);
		}
	}

	&__legalLinksSeparator {
		display: inline-block;
		margin: 0 $grid-base;
	}
}
